import { Component } from "react";
import Head from "next/head";
import {
  PAGE_TITLE_SUFFIX,
  DEFAULT_DESC,
  META_FB,
  PAGE_TITLE,
} from "../../config/head";
import {
  API_BASE_URL,
  S3_BASE_URL,
  APP_BASE_URL,
} from "../../config/constants";

export default class Meta extends Component {
  componentDidMount() {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "nextjs-nav",
        path: this.props.canonical,
        title: this.props.title
          ? `${this.props.title} ${PAGE_TITLE_SUFFIX}`
          : PAGE_TITLE,
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.canonical !== this.props.canonical) {
      window.dataLayer.push({
        event: "nextjs-nav",
        path: this.props.canonical,
        title: this.props.title
          ? `${this.props.title} ${PAGE_TITLE_SUFFIX}`
          : PAGE_TITLE,
      });
    }
  }

  render() {
    return (
      <Head>
        <title>
          {this.props.title
            ? `${this.props.title} ${PAGE_TITLE_SUFFIX}`
            : PAGE_TITLE}
        </title>

        {/* Pre-connects */}
        <link rel="preconnect" href={S3_BASE_URL} />
        <link rel="preconnect" href={API_BASE_URL} />
        <link rel="preconnect" href="https://secure.quantserve.com" />
        <link rel="preconnect" href="https://pixel.quantserve.com" />
        <link rel="preconnect" href="https://wfpscripts.webspectator.com" />
        <link rel="preconnect" href="https://webservices.webspectator.com" />
        <link rel="preconnect" href="https://msgws.webspectator.com" />
        <link rel="preconnect" href="https://stats.g.doubleclick.net" />
        <link rel="preconnect" href="https://rules.quantcount.com" />
        <link rel="preconnect" href="https://ajax.googleapis.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <link rel="preconnect" href="https://connect.facebook.net" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://www.facebook.com" />
        <link rel="preconnect" href="https://syndication.twitter.com" />
        <link rel="preconnect" href="https://sb.scorecardresearch.com" />

        <link rel="canonical" href={`${APP_BASE_URL}${this.props.canonical}`} />

        {/* General tags */}
        <meta
          name="description"
          content={this.props.description || DEFAULT_DESC}
        />

        {/* OG Facebook tags */}
        <meta property="og:locale" content="pt_BR" />
        <meta
          property="og:url"
          content={`${APP_BASE_URL}${this.props.canonical}`}
        />
        <meta
          property="og:title"
          content={
            this.props.title
              ? `${this.props.title} ${PAGE_TITLE_SUFFIX}`
              : PAGE_TITLE
          }
        />
        <meta
          property="og:description"
          content={this.props.description || DEFAULT_DESC}
        />
        <meta property="og:site_name" content="DRAFT5" />
        <meta property="og:image" content={this.props.image || META_FB} />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="281369788996885" />

        {/* Twitter Tags */}
        <meta
          name="twitter:title"
          content={
            this.props.title
              ? `${this.props.title} ${PAGE_TITLE_SUFFIX}`
              : PAGE_TITLE
          }
        />
        <meta name="twitter:type" content="website" />
        <meta
          name="twitter:text:description"
          content={this.props.description || DEFAULT_DESC}
        />
        <meta
          name="twitter:description"
          content={this.props.description || DEFAULT_DESC}
        />
        <meta name="twitter:image" content={this.props.image || META_FB} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Draft5gg" />

        {/* Google Search */}
        <meta name="robots" content="max-image-preview:large"></meta>
      </Head>
    );
  }
}
