import axios from "axios";
import { API_BASE_URL } from "../config/constants";

export default class TeamsService {
  static getHeadToHead(teamAId, teamBId) {
    const url = `${API_BASE_URL}/teams/${teamAId}/vs/${teamBId}`;

    return axios.get(url);
  }

  static getTeamDetail(teamId, titular = false, actual = false) {
    let url = `${API_BASE_URL}/teams/${teamId}?`;
    titular ? (url += "titular=true&") : "";
    actual ? (url += "actual=true") : "";
    return axios.get(url);
  }

  static getTeams(page, amount, searchQuery, isRecommended = false) {
    const url = `${API_BASE_URL}/teams`;

    return axios.get(url, {
      params: {
        page,
        amount,
        searchQuery,
        isRecommended,
      },
      query: {
        searchQuery,
      },
    });
  }
}
