import React, { Component } from 'react';

import TeamLogo from '../TeamLogo';
import { S3_BASE_URL } from '../../../config/constants';
import styled from 'styled-components';
import {colors} from '../../../config/styles';

class OnboardingSelectedItems extends Component {
  render() {
    const { favorites } = this.props;

    return (
      <SelectedItems>
        <ItemList>
          <ItemListTitle>Times</ItemListTitle>

          <ItemListContainer>
            {
              favorites.teams.map(team => (
                <Item>
                  <Logo teamId={team.teamId} size={32} />
                  <span>{team.teamName}</span>
                </Item>
              ))
            }

            {
              favorites.teams.length === 0 &&
              <Empty>
                Nenhum time selecionado
              </Empty>
            }
          </ItemListContainer>
        </ItemList>
        <ItemList>
          <ItemListTitle>Campeonatos</ItemListTitle>

          <ItemListContainer>
            {
              favorites.tournaments.map(tournament => (
                <Item>
                  <Image
                    style={{
                      backgroundImage: `
                        ${
                          tournament.tournamentImage ?
                            `url(${S3_BASE_URL}/${tournament.tournamentImage})` :
                            `url(${S3_BASE_URL}/assets/team-logo-placeholder.png)`
                        }
                      `,
                    }}
                  />
                  <span>{tournament.tournamentName}</span>
                </Item>
              ))
            }

            {
              favorites.tournaments.length === 0 &&
              <Empty>
                Nenhum campeonato selecionado
              </Empty>
            }
          </ItemListContainer>
        </ItemList>
      </SelectedItems>
    );
  }
}

const SelectedItems = styled.div`
  display: flex;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`

const ItemList = styled.div`
  flex: 1;
`

const ItemListContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  height: 330px;

  @media screen and (max-width: 576px) {
    height: 200px;
  }
`

const ItemListTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  margin: 12px 0;
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 0px;
  background: transparent;
  height: 60px;

  span {
    flex: 1;
    text-align: left;
    font-size: 14px;
  }
`

const Logo = styled(TeamLogo)`
  margin-right: 12px;
`

const Image = styled.div`
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: cover;
  margin-right: 12px;
`

const Empty = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.GRAY_LIGHT};
  height: 100%;
`

export default OnboardingSelectedItems;
