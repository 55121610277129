import React, { Component } from "react";
import styled from "styled-components";
import Button, { buttonThemes, buttonSizes } from "../Shared/Button";

export default class WarningLGPD extends Component {
  render() {
    const { AcceptLGPDTerms } = this.props;

    return (
      <WarnContainer>
        <WarnBox>
          <p>
            Nós utilizamos cookies e outras tecnologias semelhantes para
            melhorar a sua experiência em nossos serviços, personalizar
            publicidade e recomendar conteúdo de seu interesse. Para saber mais
            detalhes consulte a nossa{" "}
            <ExternalLink
              href="https://gamersclub.movidesk.com/kb/article/192935/politica-de-cookies"
              target="_blank"
              rel="noreferrer noopener"
            >
              Política de Cookies
            </ExternalLink>{" "}
            e{" "}
            <ExternalLink
              href="https://gamersclub.movidesk.com/kb/pt-br/article/123537/politica-de-privacidade"
              target="_blank"
              rel="noreferrer noopener"
            >
              Política de Privacidade.
            </ExternalLink>
          </p>
          <AgreeButton
            theme={buttonThemes.default.ACCENT}
            size={buttonSizes.TINY}
            text="OK"
            onClick={() => {
              AcceptLGPDTerms();
            }}
          />
        </WarnBox>
      </WarnContainer>
    );
  }
}

const WarnContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 90px;
  z-index: 14;
  pointer-events: none;
`;

const WarnBox = styled.div`
  position: fixed;
  width: 300px;
  bottom: 10px;
  left: 10px;
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  font-size: 14px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 768px;

  p {
    flex: 1;
    margin-top: 0px;
  }

  @media screen and (max-width: 1340px) {
    position: static;
    bottom: 0px;
    padding: 12px;
    width: 100vw;
    max-width: 731px;
    box-sizing: border-box;
    margin: auto;
    left: 0;
    bottom: 90px;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 24px;
    font-size: 22px;

    p {
      margin-top: 0;
    }

    a {
      font-size: 22px;
      width: 100%;
    }
  }
`;

const ExternalLink = styled.a`
  color: #d5d5d5;
  text-decoration: underline;
`;

const AgreeButton = styled(Button)`
  margin-left: 12px;
  margin-right: 24px;
  min-width: 120px;

  @media screen and (max-width: 1000px) {
    min-width: 80px;
    margin-right: 12px;
    width: 100%;
  }
`;
