import React, { Component, Fragment } from "react";

// components
import Card from "../Card";
import Button, { buttonThemes, buttonSizes } from "../Button";
import OnboardingItemList from "./OnboardingItemList";
import OnboardingSelectedItems from "./OnboardingSelectedItems";
import CompleteProfileForm from "./CompleteProfileForm";

// context
import UserService from "../../../services/user";
import styled from "styled-components";
import { colors } from "../../../config/styles";
import { UserContext } from "../../../contexts/UserContext";
import Router from "next/router";

class UserOnboarding extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 1,
      onboardingVisible: true,
      favorites: {
        teams: [],
        tournaments: [],
      },
      isLoading: false,
    };
  }

  onFavoriteTeamAdd = (selectedTeam) => {
    this.setState({
      favorites: {
        ...this.state.favorites,
        teams: [...this.state.favorites.teams, selectedTeam],
      },
    });
  };

  onFavoriteTeamRemove = (selectedTeam) => {
    this.setState({
      favorites: {
        ...this.state.favorites,
        teams: this.state.favorites.teams.filter(
          (team) => team.teamId !== selectedTeam.teamId
        ),
      },
    });
  };

  onFavoriteTournamentAdd = (selectedTournaments) => {
    this.setState({
      favorites: {
        ...this.state.favorites,
        tournaments: [...this.state.favorites.tournaments, selectedTournaments],
      },
    });
  };

  onFavoriteTournamentRemove = (selectedTournament) => {
    this.setState({
      favorites: {
        ...this.state.favorites,
        tournaments: this.state.favorites.tournaments.filter(
          (tournament) =>
            tournament.tournamentId !== selectedTournament.tournamentId
        ),
      },
    });
  };

  onFormSubmit = (values) => {
    const { teams, tournaments } = this.state.favorites;
    const { userData } = this.context;

    const updateUserFavoritesPromise = UserService.updateUserFavorites(
      teams,
      tournaments
    );
    const updateUserDataPromise = UserService.updateUserData(
      values.email,
      values.fullName,
      userData.displayName,
      userData.newsletterEnabled,
      true
    );

    this.setState({ isLoading: true });

    Promise.all([updateUserFavoritesPromise, updateUserDataPromise]).then(
      () => {
        this.setState({
          isLoading: false,
          onboardingVisible: false,
        });
        Router.push({
          pathname: "/perfil/favoritos",
          query: { tutorial: 1 },
        });
        window.location.reload(true);
      }
    );
  };

  bindSubmitForm = (submitForm) => {
    this.submitForm = submitForm;
  };

  handleFormSubmit = () => {
    this.submitForm();
  };

  decreaseStep = () => {
    const { activeStep } = this.state;

    if (activeStep === 1) return;

    this.setState({
      activeStep: activeStep - 1,
    });
  };

  increaseStep = () => {
    const { activeStep } = this.state;

    if (activeStep === 4) return;

    this.setState({
      activeStep: activeStep + 1,
    });
  };

  render() {
    const { activeStep, favorites, isLoading, onboardingVisible } = this.state;

    const { userData } = this.context;

    if (!onboardingVisible) return "";

    return (
      <Backdrop>
        <UserOnboardingContainer>
          <UserOnboardingClose
            name="close"
            size={buttonSizes.TINY}
            theme={buttonThemes.outline.ERROR}
            outline
            onClick={() => {
              this.setState({
                onboardingVisible: false,
              });
            }}
          />

          <Greet>
            Olá {userData.fullName}, personalize sua experiência na DRAFT5
          </Greet>

          {activeStep === 1 && (
            <Fragment>
              <h2>Escolha seus times favoritos</h2>
              <OnboardingItemList
                onFavoriteAdd={this.onFavoriteTeamAdd}
                onFavoriteRemove={this.onFavoriteTeamRemove}
                favorites={favorites.teams}
                type="teams"
              />
            </Fragment>
          )}
          {activeStep === 2 && (
            <Fragment>
              <h2>Gostaria de acompanhar algum campeonato?</h2>
              <OnboardingItemList
                onFavoriteAdd={this.onFavoriteTournamentAdd}
                onFavoriteRemove={this.onFavoriteTournamentRemove}
                favorites={favorites.tournaments}
                type="tournaments"
              />
            </Fragment>
          )}
          {activeStep === 3 && (
            <Fragment>
              <h2>Estas são suas escolhas</h2>
              <OnboardingSelectedItems favorites={favorites} />
            </Fragment>
          )}

          {activeStep === 4 && (
            <Fragment>
              <h2>Preencha seus dados para completar seu perfil</h2>
              <CompleteProfileForm
                bindSubmitForm={this.bindSubmitForm}
                onFormSubmit={this.onFormSubmit}
              />
            </Fragment>
          )}

          <Navigation>
            <Button
              theme={buttonThemes.default.ACCENT}
              onClick={this.decreaseStep}
              size={buttonSizes.MEDIUM}
              text="Anterior"
              disabled={activeStep === 1}
            />

            <NavigationSteps>
              <NavigationStep active={activeStep === 1} />
              <NavigationStep active={activeStep === 2} />
              <NavigationStep active={activeStep === 3} />
              <NavigationStep active={activeStep === 4} />
            </NavigationSteps>

            {activeStep < 4 && (
              <Button
                theme={buttonThemes.default.ACCENT}
                onClick={this.increaseStep}
                size={buttonSizes.MEDIUM}
                text="Próximo"
                disabled={activeStep === 4}
              />
            )}

            {activeStep === 4 && (
              <Button
                theme={buttonThemes.default.ACCENT}
                onClick={this.handleFormSubmit}
                size={buttonSizes.MEDIUM}
                text="Concluir"
                isLoading={isLoading}
              />
            )}
          </Navigation>
        </UserOnboardingContainer>
      </Backdrop>
    );
  }
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  overflow: auto;
`;

const UserOnboardingContainer = styled(Card)`
  background: white;
  width: 700px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  padding: 32px 24px 24px 24px;
  position: relative;

  h2 {
    font-size: 24px;
    font-weight: normal;
    color: ${colors.MAIN};
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    & h2 {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 576px) {
    height: 100vh;
  }
`;

const UserOnboardingClose = styled(Button)`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  &:hover {
    top: 14px;
  }
`;

const Greet = styled.p`
  font-size: 15px;
  color: ${colors.GRAY_LIGHT};
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;

  @media screen and (max-width: 576px) {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background: white;
    margin-top: 0;
    padding: 20px;
  }
`;

const NavigationSteps = styled.div`
  display: flex;
`;

const NavigationStep = styled.span`
  width: 12px;
  height: 12px;
  display: block;
  background: ${(props) =>
    props.active ? colors.ACCENT : colors.GRAY_LIGHTER};
  border-radius: 12px;
  margin: 0 10px;
`;

export default UserOnboarding;
