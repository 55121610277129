/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * NÃO APAGAR OS "ADSLOT"s DOS
 * ANÚNCIOS QUANDO CONFIGURAR UMA CAMPANHA CUSTOM,
 * JÁ QUE ELES SÃO CONFIGURADOS APENAS UMA VEZ NO
 * PAINEL DO ADSENSE
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * ====================== **
 * ADSENSE
 * ====================== **
 * KEY_DO_ANUNCIO: {
 *  active: false | true,
 *  type: adTypes.ADSENSE,
 *  adSlot: <número do adslot do google adsense>
 *  auto: true (responsivo) | false (tamanho fixo)
 *  format: se não for auto, especificar formato (rectangle, horizontal, auto)
 * }
 *
 * ====================== **
 * AD CUSTOM
 * ====================== **
 * KEY_DO_ANUNCIO: {
 *  active: false | true,
 *  type: adTypes.CUSTOM,
 *  imageUrl: <URL da imagem, static.draft5.gg/xxxxxx>,
 *  imageLink: <URL da campanha>,
 *  analyticsKey: (Chave interna do analytics),
 * }
 */

export const adTypes = {
  ADSENSE: 1,
  CUSTOM: 2,
};

export const adSpots = {
  /**
   * PECULIARIDADES DO BACKGROUND:
   *
   * @param backgroundColor: sempre bom especificar, caso BG não ocupe
   * 100% da largura da tela, em alguns casos,
   *
   * @param imageUrls: Objeto com 3 variações de tamanhos do background, para aparecer
   * em todas as resoluções possíveis.
   * Os breakpoints configurados são:
   * - Telas de no mínimo 1820px de largura exibirão o LARGE,
   * - Telas de no mínimo 1500px de largura exibirão o MEDIUM,
   * - Telas de no mínimo 1440px de largura exibirão o SMALL,
   * - Telas abaixo de 1440px de largura exibirão o TINY,
   * MEDIUM, SMALL e TINY não são obrigatórios, caso sejam null, o site usará o LARGE.
   * Caso necessite editar os valores em pixel dos tamanhos, atualizar o objeto
   * "breakpoints" no arquivo App.js, dentro do método getBackgroundImageUrl();
   */
  BACKGROUND: {
    active: true,
    backgroundColor: "#b1b1b1",
    imageUrls: {
      LARGE: "https://static.draft5.gg/partners/dell/dell-bg-large.jpg",
      MEDIUM: "https://static.draft5.gg/partners/dell/dell-bg-medium.jpg",
      SMALL: "https://static.draft5.gg/partners/dell/dell-bg-small.jpg",
      // TINY: '',
    },
    imageLink:
      "https://ad.doubleclick.net/ddm/trackclk/N1153793.3738589GAMERSCLUB/B24035212.277213787;dc_trk_aid=471596546;dc_trk_cid=131826271;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=",
    analyticsKey: "DELL-BG-200919",
  },
};

export const socialLink = {
  googleNews: {
    analyticsKey: "20201217Seguir-googleNews",
  },
  facebook: {
    analyticsKey: "20201217CompartilharNoticia-facebook",
  },
  twitter: {
    analyticsKey: "20201217CompartilharNoticia-twitter",
  },
  whatsapp: {
    analyticsKey: "20201217CompartilharNoticia-whatsapp",
  },
  telegram: {
    analyticsKey: "20201217CompartilharNoticia-telegram",
  },
  whatsappGroup: {
    analyticsKey: "20231705EntrarGrupo-Whatsapp",
  },
};
