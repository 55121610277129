const billboardSizes = [[468, 60], [728, 90], "fluid", [1, 1]];
const largeRectangleSizes = [
  [300, 250],
  [300, 100],
  [320, 480],
  [320, 100],
  "fluid",
  [250, 250],
  [336, 280],
  [1, 1],
];

const squareSizes = [
  [216, 36],
  [168, 28],
  [234, 60],
  [180, 150],
  [220, 90],
  [200, 200],
  "fluid",
  [125, 125],
  [240, 133],
  [120, 240],
  [216, 54],
  [250, 250],
  [168, 42],
  [1, 1],
];

const GPT_IDS = {
  // =====================================
  // BY PAGE, NEW format
  // =====================================
  nextMatches: {
    NEXT_MATCHES_LARGE_RECTANGLE: {
      slotName: "/1056794/draft5.gg/NEXT_MATCHES_LARGE_RECTANGLE",
      sizes: [
        [1, 1],
        [300, 250],
        [300, 100],
        [320, 480],
        [320, 100],
        [336, 280],
        [250, 250],
      ],
      divId: "div-gpt-ad-1613592380268-0",
    },
    NEXT_MATCHES_LIVE_ODDS: {
      slotName: "/1056794/draft5.gg/Partida_LiveOdd",
      sizes: [
        [300, 600],
        [300, 250],
      ],
      divId: "div-gpt-ad-1614720217620-0",
    },
  },

  results: {
    RESULTS_LARGE_RECTANGLE: {
      slotName: "/1056794/draft5.gg/RESULTS_LARGE_RECTANGLE",
      sizes: [
        [1, 1],
        [300, 250],
        [300, 100],
        [320, 480],
        [320, 100],
        [336, 280],
        [250, 250],
      ],
      divId: "div-gpt-ad-1613594621444-0",
    },
    RESULTS_LIVE_ODDS: {
      slotName: "/1056794/draft5.gg/Resultados_LiveOdds",
      sizes: [
        [300, 600],
        [300, 250],
      ],
      divId: "div-gpt-ad-1614720218915-0",
    },
  },

  ranking: {
    RANKING_BILLBOARD: {
      slotName: "/1056794/draft5.gg/RANKING_BILLBOARD",
      sizes: [[468, 60], [1, 1], [728, 90], "fluid"],
      divId: "div-gpt-ad-1613594946516-0",
    },
    RANKING_SMALL_SQUARE: {
      slotName: "/1056794/draft5.gg/RANKING_SMALL_SQUARE",
      sizes: ["fluid", [240, 133], [240, 400], [250, 250], [250, 360]],
      divId: "div-gpt-ad-1613599794626-0",
    },
  },

  transfers: {
    TRANSFERS_BILLBOARD: {
      slotName: "/1056794/draft5.gg/TRANSFERS_BILLBOARD",
      sizes: [[1, 1], [468, 60], "fluid", [728, 90]],
      divId: "div-gpt-ad-1613595830288-0",
    },
  },

  podcasts: {
    PODCASTS_BILLBOARD: {
      slotName: "/1056794/draft5.gg/PODCASTS_BILLBOARD",
      sizes: ["fluid", [1, 1], [728, 90], [468, 60]],
      divId: "div-gpt-ad-1613596207409-0",
    },
  },

  matchDetail: {
    SUPER_CONFRONTO: {
      slotName: "/1056794/draft5.gg/Super_Confronto",
      sizes: [[728, 90]],
      divId: "div-gpt-ad-1622471920541-0",
    },
    MATCH_DETAIL_VERTICAL_BANNER: {
      slotName: "/1056794/draft5.gg/MATCH_DETAIL_VERTICAL_BANNER",
      sizes: [
        [120, 240],
        [120, 30],
        [120, 20],
        [120, 90],
        [150, 250],
        [125, 125],
        "fluid",
        [190, 250],
        [120, 60],
      ],
      divId: "div-gpt-ad-1613590679868-0",
    },
  },

  home: {
    LIVE_ODDS_HOME_RIGHT: {
      slotName: "/1056794/draft5.gg/Home_Right_Odds",
      sizes: [
        [300, 250],
        [300, 600],
      ],
      divId: "div-gpt-ad-1614719974106-0",
    },
    LIVE_ODDS_HOME_LEFT: {
      slotName: "/1056794/draft5.gg/Home_Left_Odds",
      sizes: [
        [300, 250],
        [300, 600],
      ],
      divId: "div-gpt-ad-1614719819868-0",
    },
  },

  newsDetail: {
    NEWS_DETAIL_INTERSTITIAL: {
      slotName: "/1056794/draft5.gg/Interstitial_noticias",
      sizes: [
        [300, 250],
        [728, 90],
      ],
      divId: "div-gpt-ad-1614720225518-0",
    },
    NEWS_DETAIL_MID_INFINITE_SCROLLING: {
      slotName: "/1056794/draft5.gg/728x90SuperNoticias",
      sizes: [[728, 90]],
      divId: "div-gpt-ad-1623186492610-0",
    },
  },

  playByPlay: {
    PLAY_BY_PLAY_INTERSTITIAL: {
      slotName: "/1056794/draft5.gg/Interstitial_PlayByPlay",
      sizes: [
        [300, 250],
        [728, 90],
      ],
      divId: "div-gpt-ad-1615235425989-0",
    },
  },

  teamDetail: {
    TEAM_DETAIL_SQUARE: {
      slotName: "/1056794/draft5.gg/Arroba_novo",
      sizes: [[300, 250], ...squareSizes],
      divId: "div-gpt-ad-1625587383179-0",
    },
  },

  shared: {
    BOTTOM_FIXED_SUPER_BANNER: {
      slotName: "BOTTOM_FIXED_SUPER_BANNER",
      // customImage: true,
      // campaignName: "Midnite-PGLMajor",
      // shouldStack: false,
      // customImageData: {
      //   imageUrl:
      //     "https://static.draft5.gg/partners/midnite/pglmajor/MIDNITE_PGLMAJOR_Draft5_SB.png",
      //   imageLink: "https://midnite.app.link/e/d2UFUFl2Kkb",
      //   imageSize: "728x90",
      // },
    },
  },

  // =====================================
  // Old Format
  // =====================================
  BACKGROUND_TAKEOVER_LEFT: {
    slotName: "/1056794/draft5.gg/BACKGROUND_TAKEOVER_LEFT",
    sizes: [
      [280, 700],
      [300, 250],
      [1, 1],
    ],
    divId: "div-gpt-ad-1607701372357-0",
    // customImage: true,
    // campaignName: "Midnite-PGLMajor",
    // shouldStack: false,
    // customImageData: {
    //   imageUrl:
    //     "https://static.draft5.gg/partners/midnite/pglmajor/MIDNITE_PGLMAJOR_Draft5_280x700.png",
    //   imageLink: "https://midnite.app.link/e/d2UFUFl2Kkb",
    //   imageSize: "280x700",
    // },
  },
  BACKGROUND_TAKEOVER_RIGHT: {
    slotName: "/1056794/draft5.gg/BACKGROUND_TAKEOVER_RIGHT",
    sizes: [
      [280, 700],
      [300, 250],
      [1, 1],
    ],
    divId: "div-gpt-ad-1607702318197-0",
    // customImage: true,
    // campaignName: "Midnite-PGLMajor",
    // shouldStack: false,
    // customImageData: {
    //   imageUrl:
    //     "https://static.draft5.gg/partners/midnite/pglmajor/MIDNITE_PGLMAJOR_Draft5_280x700.png",
    //   imageLink: "https://midnite.app.link/e/d2UFUFl2Kkb",
    //   imageSize: "280x700",
    // },
  },

  // BILLBOARDS
  HOMEPAGE_BILLBOARD: {
    slotName: "/1056794/draft5.gg/HOMEPAGE_BILLBOARD",
    sizes: billboardSizes,
    divId: "div-gpt-ad-1599846992992-0",
  },
  NEXT_MATCHES_BILLBOARD: {
    slotName: "/1056794/draft5.gg/NEXT_MATCHES_BILLBOARD",
    sizes: billboardSizes,
    divId: "div-gpt-ad-1599849019071-0",
  },
  RESULTS_BILLBOARD: {
    slotName: "/1056794/draft5.gg/HOMEPAGE_BILLBOARD",
    sizes: billboardSizes,
    divId: "div-gpt-ad-1599856199607-0",
  },
  TOURNAMENTS_BILLBOARD: {
    slotName: "/1056794/draft5.gg/TOURNAMENTS_BILLBOARD",
    sizes: billboardSizes,
    divId: "div-gpt-ad-1599856932731-0",
  },
  PAST_TOURNAMENTS_BILLBOARD: {
    slotName: "/1056794/draft5.gg/PAST_TOURNAMENTS_BILLBOARD",
    sizes: billboardSizes,
    divId: "div-gpt-ad-1599857446993-0",
  },
  TOURNAMENT_DETAIL_BILLBOARD: {
    slotName: "/1056794/draft5.gg/TOURNAMENT_DETAIL_BILLBOARD",
    sizes: billboardSizes,
    divId: "div-gpt-ad-1599857783564-0",
  },
  TEAM_DETAIL_BILLBOARD: {
    slotName: "/1056794/draft5.gg/TEAM_DETAIL_BILLBOARD",
    sizes: billboardSizes,
    divId: "div-gpt-ad-1599858125161-0",
  },
  NEWS_DETAIL_BILLBOARD: {
    slotName: "/1056794/draft5.gg/NEWS_DETAIL_BILLBOARD",
    sizes: [
      [728, 90],
      [468, 60],
      [1, 1],
    ],
    divId: "div-gpt-ad-1599858468226-0",
  },
  PLAY_BY_PLAY_BILLBOARD: {
    slotName: "/1056794/draft5.gg/PLAY_BY_PLAY_BILLBOARD",
    sizes: [[468, 60], [728, 90], "fluid"],
    divId: "div-gpt-ad-1603298330601-0",
  },

  // LARGE RECTANGLE
  TOURNAMENT_DETAIL_LARGE_RECTANGLE: {
    slotName: "/1056794/draft5.gg/TOURNAMENT_DETAIL_LARGE_RECTANGLE",
    sizes: largeRectangleSizes,
    divId: "div-gpt-ad-1599859344244-0",
  },
  HOMEPAGE_LARGE_RECTANGLE_RIGHT: {
    slotName: "/1056794/draft5.gg/HOMEPAGE_LARGE_RECTANGLE_RIGHT",
    sizes: largeRectangleSizes,
    divId: "div-gpt-ad-1600096834843-0",
  },
  HOMEPAGE_LARGE_RECTANGLE_RIGHT_BOTTOM: {
    slotName: "/1056794/draft5.gg/HOMEPAGE_LARGE_RECTANGLE_RIGHT_BOTTOM",
    sizes: largeRectangleSizes,
    divId: "div-gpt-ad-1600097250224-0",
  },

  // SQUARE
  MATCH_DETAIL_SQUARE: {
    slotName: "/1056794/draft5.gg/MATCH_DETAIL_SQUARE",
    sizes: squareSizes,
    divId: "div-gpt-ad-1600094677343-0",
  },
  HOMEPAGE_SQUARE_LEFT: {
    slotName: "/1056794/draft5.gg/HOMEPAGE_SQUARE_LEFT",
    sizes: [
      [125, 125],
      [240, 133],
      [216, 36],
      [300, 250],
      [220, 90],
      [250, 250],
      [168, 42],
      [1, 1],
      "fluid",
      [120, 240],
      [168, 28],
      [180, 150],
      [216, 54],
      [234, 60],
      [200, 200],
    ],
    divId: "div-gpt-ad-1607626380525-0",
  },
  HOMEPAGE_SQUARE_LEFT_BOTTOM: {
    slotName: "/1056794/draft5.gg/HOMEPAGE_SQUARE_LEFT_BOTTOM",
    sizes: squareSizes,
    divId: "div-gpt-ad-1600098031822-0",
  },
  NEWS_DETAIL_SQUARE: {
    slotName: "/1056794/draft5.gg/NEWS_DETAIL_SQUARE",
    sizes: [
      [220, 90],
      [250, 250],
      [300, 250],
      [168, 42],
      [168, 28],
      [180, 150],
      "fluid",
      [200, 200],
      [216, 54],
      [234, 60],
      [240, 133],
      [125, 125],
      [216, 36],
      [120, 240],
    ],
    divId: "div-gpt-ad-1600098557933-0",
    // customImage: true,
    // campaignName: "Midnite-PGLMajor",
    // shouldStack: false,
    // customImageData: {
    //   imageUrl:
    //     "https://static.draft5.gg/partners/midnite/pglmajor/MIDNITE_PGLMAJOR_Draft5_SQ.png",
    //   imageLink: "https://midnite.app.link/e/d2UFUFl2Kkb",
    //   imageSize: "300x250",
    // },
  },
  PLAY_BY_PLAY_SQUARE: {
    slotName: "/1056794/draft5.gg/PLAY_BY_PLAY_SQUARE",
    sizes: squareSizes,
    divId: "div-gpt-ad-1603299195976-0",
  },
  NEWS_SIDEMENU_SQUARE: {
    slotName: "/1056794/draft5.gg/NEWS_DETAIL_SQUARE",
    sizes: squareSizes,
    divId: "div-gpt-ad-1600098557933-0",
  },

  //BET HOUSE PAGE
  BETHOUSE_RECTANGLE_RIGHT_TOP: {
    slotName: "/1056794/draft5.gg/BETHOUSE_RECTANGLE_RIGHT_TOP",
    sizes: [[300, 250], [1, 1],],
    divId: "div-gpt-ad-1600098557933-0",
  },
};

const initAdManager = () => {
  window.googletag = window.googletag || { cmd: [] };
  googletag.cmd.push(function () {
    googletag.pubads().enableSingleRequest();
    googletag.pubads().collapseEmptyDivs();
    googletag.enableServices();
  });
};

export { initAdManager };
export default GPT_IDS;
