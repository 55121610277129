import React, { Component } from 'react';
import styled from 'styled-components';
import { colors } from '../../config/styles';

class Input extends Component {
  render() {
    const {
      label,
      inputName,
      type,
      placeholder,
    } = this.props;

    return (
      <InputLabel htmlFor={inputName} className={this.props.className || ''}>
        {label}
        <InputContainer
          type={type}
          name={inputName}
          placeholder={placeholder}
          {...this.props}
        />
      </InputLabel>
    );
  }
}

const InputContainer = styled.input`
  border-radius: 2px;
  display: block;
  width: 100%;
  border: 1px solid ${colors.GRAY_LIGHT};
  font-size: 16px;
  line-height: 1.5;
  padding: 10px;
  color: ${colors.GRAY_DARK}; 
  font-family: 'Roboto', sans-serif;
  transition: all 0.2s;

  margin-top: 4px;

  &:focus {
    border-color: ${colors.ACCENT};
  }
`

const InputLabel = styled.label`
  font-size: 14px;
  color: ${colors.GRAY};
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
`


export default Input;
