import React, { Component, Fragment } from "react";
import debounce from "lodash/debounce";

// COMPONENTS
import TeamLogo from "../TeamLogo";
import Button, { buttonThemes, buttonSizes } from "../Button";
import TeamsService from "../../../services/teams";

// css
//import './styles.css';
import Input from "../Input";
import LoadingIndicator from "../LoadingIndicator";
import TournamentsService from "../../../services/tournaments";
import {
  S3_BASE_URL,
  PRIORITIZED_ONBOARDING_TEAMS,
  PRIORITIZED_ONBOARDING_TOURNAMENTS,
} from "../../../config/constants";
import styled from "styled-components";
import { colors } from "../../../config/styles";

class OnboardingItemList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      teams: [],
      tournaments: [],
      favorites: {
        teams: props.type === "teams" ? props.favorites : [],
        tournaments: props.type === "tournaments" ? props.favorites : [],
      },
    };

    this.debouncedSearch = debounce(this.getItems, 1000);
  }

  componentDidMount() {
    this.getItems();
  }

  getItems = (searchQuery = null) => {
    const { type } = this.props;

    this.setState({ isLoading: true });

    if (type === "teams") {
      TeamsService.getTeams(1, 50, searchQuery, !searchQuery).then(
        (response) => {
          this.setState({
            teams: response.data.data.sort((a) => {
              if (PRIORITIZED_ONBOARDING_TEAMS.includes(a.teamId)) return -1;

              return 1;
            }),
            isLoading: false,
          });
        }
      );
    }

    if (type === "tournaments") {
      TournamentsService.getTournaments(
        1,
        50,
        false,
        false,
        null,
        searchQuery
      ).then((response) => {
        this.setState({
          tournaments: response.data.data.sort((a) => {
            if (PRIORITIZED_ONBOARDING_TOURNAMENTS.includes(a.tournamentId))
              return -1;

            return 1;
          }),
          isLoading: false,
        });
      });
    }
  };

  searchItems = (ev) => {
    this.setState({ isLoading: true });
    this.debouncedSearch(ev.target.value);
  };

  addItemToFavorites = (selectedItem) => {
    const { onFavoriteAdd, type } = this.props;

    onFavoriteAdd(selectedItem);

    if (type === "teams") {
      this.setState({
        favorites: {
          ...this.state.favorites,
          teams: [...this.state.favorites.teams, selectedItem],
        },
      });
    }

    if (type === "tournaments") {
      this.setState({
        favorites: {
          ...this.state.favorites,
          tournaments: [...this.state.favorites.tournaments, selectedItem],
        },
      });
    }
  };

  removeItemFromFavorites = (selectedItem) => {
    const { type, onFavoriteRemove } = this.props;

    onFavoriteRemove(selectedItem);

    if (type === "teams") {
      this.setState({
        teams: [
          selectedItem,
          ...this.state.teams.filter(
            (team) => team.teamId !== selectedItem.teamId
          ),
        ],
        favorites: {
          ...this.state.favorites,
          teams: this.state.favorites.teams.filter(
            (team) => team.teamId !== selectedItem.teamId
          ),
        },
      });
    }

    if (type === "tournaments") {
      this.setState({
        tournaments: [
          selectedItem,
          ...this.state.tournaments.filter(
            (tournament) =>
              tournament.tournamentId !== selectedItem.tournamentId
          ),
        ],
        favorites: {
          ...this.state.favorites,
          tournaments: this.state.favorites.tournaments.filter(
            (tournament) =>
              tournament.tournamentId !== selectedItem.tournamentId
          ),
        },
      });
    }
  };

  isFavorited = (selectedItem) => {
    const { favorites } = this.state;
    const { type } = this.props;

    if (type === "teams")
      return (
        favorites.teams.filter((team) => team.teamId === selectedItem.teamId)
          .length > 0
      );
    if (type === "tournaments")
      return (
        favorites.tournaments.filter(
          (tournament) => tournament.tournamentId === selectedItem.tournamentId
        ).length > 0
      );

    return false;
  };

  renderTeamSelection() {
    const { teams, favorites, isLoading } = this.state;

    return (
      <Fragment>
        <Input
          name="searchTeam"
          placeholder="Busque times por nome"
          onChange={this.searchItems}
        />

        <SelectionsContainer>
          <OnboardingList>
            <OnboardingTitle>Sugestões de Times</OnboardingTitle>
            {/* ================== */}
            {/* LOADING STATE ==== */}
            {/* ================== */}
            {isLoading && (
              <OnboardingContainer>
                <LoadingIndicator text="Carregando..." />
              </OnboardingContainer>
            )}

            {/* ================== */}
            {/* EMPTY STATE ====== */}
            {/* ================== */}
            {!isLoading && teams.length === 0 && (
              <OnboardingContainer>
                <EmptyState>Nenhum time encontrado.</EmptyState>
              </OnboardingContainer>
            )}

            {/* ================== */}
            {/* NORMAL STATE ===== */}
            {/* ================== */}
            {!isLoading && teams.length > 0 && (
              <OnboardingContainer>
                {teams.map((team) => (
                  <OnboardingListItem
                    onClick={() => {
                      if (!this.isFavorited(team)) {
                        this.addItemToFavorites(team);
                      }
                    }}
                  >
                    <Logo teamId={team.teamId} size={32} />
                    <span>{team.teamName}</span>
                    {!this.isFavorited(team) && (
                      <Button
                        theme={buttonThemes.outline.ACCENT}
                        size={buttonSizes.SMALL}
                        text="Seguir"
                      />
                    )}
                  </OnboardingListItem>
                ))}
              </OnboardingContainer>
            )}
          </OnboardingList>

          <OnboardingSelections>
            <OnboardingTitle>Meus Times</OnboardingTitle>

            <OnboardingContainer>
              {favorites.teams.map((team) => (
                <OnboardingListItem
                  onClick={() => {
                    this.removeItemFromFavorites(team);
                  }}
                >
                  <Logo teamId={team.teamId} size={32} />
                  <span>{team.teamName}</span>
                  <Button
                    theme={buttonThemes.outline.ERROR}
                    size={buttonSizes.SMALL}
                    text="Deixar de Seguir"
                  />
                </OnboardingListItem>
              ))}
              {favorites.teams.length === 0 && (
                <EmptyState>Nenhum time selecionado.</EmptyState>
              )}
            </OnboardingContainer>
          </OnboardingSelections>
        </SelectionsContainer>
      </Fragment>
    );
  }

  renderTournamentSelection() {
    const { tournaments, favorites, isLoading } = this.state;

    return (
      <Fragment>
        <Input
          name="searchTeam"
          placeholder="Busque campeonatos por nome"
          onChange={this.searchItems}
        />

        <SelectionsContainer>
          <OnboardingList>
            <OnboardingTitle>Sugestões de Campeonatos</OnboardingTitle>
            {/* ================== */}
            {/* LOADING STATE ==== */}
            {/* ================== */}
            {isLoading && (
              <OnboardingContainer>
                <LoadingIndicator text="Carregando..." />
              </OnboardingContainer>
            )}

            {/* ================== */}
            {/* EMPTY STATE ====== */}
            {/* ================== */}
            {!isLoading && tournaments.length === 0 && (
              <OnboardingContainer>
                <EmptyState>Nenhum campeonato encontrado.</EmptyState>
              </OnboardingContainer>
            )}

            {/* ================== */}
            {/* NORMAL STATE ===== */}
            {/* ================== */}
            {!isLoading && tournaments.length > 0 && (
              <OnboardingContainer>
                {tournaments.map((tournament) => (
                  <OnboardingListItem
                    onClick={() => {
                      if (!this.isFavorited(tournament)) {
                        this.addItemToFavorites(tournament);
                      }
                    }}
                  >
                    <Image
                      style={{
                        backgroundImage: `
                            ${
                              tournament.tournamentImage
                                ? `url(${S3_BASE_URL}/${tournament.tournamentImage})`
                                : `url(${S3_BASE_URL}/assets/team-logo-placeholder.png)`
                            }
                          `,
                      }}
                    />
                    <span>{tournament.tournamentName}</span>
                    {!this.isFavorited(tournament) && (
                      <Button
                        theme={buttonThemes.outline.ACCENT}
                        size={buttonSizes.SMALL}
                        text="Seguir"
                      />
                    )}
                  </OnboardingListItem>
                ))}
              </OnboardingContainer>
            )}
          </OnboardingList>

          <OnboardingSelections>
            <OnboardingTitle>Meus Campeonatos</OnboardingTitle>

            <OnboardingContainer>
              {favorites.tournaments.map((tournament) => (
                <OnboardingListItem
                  onClick={() => {
                    this.removeItemFromFavorites(tournament);
                  }}
                >
                  <Image
                    style={{
                      backgroundImage: `
                          ${
                            tournament.tournamentImage
                              ? `url(${S3_BASE_URL}/${tournament.tournamentImage})`
                              : `url(${S3_BASE_URL}/assets/team-logo-placeholder.png)`
                          }
                        `,
                    }}
                  />
                  <span>{tournament.tournamentName}</span>
                  <Button
                    theme={buttonThemes.outline.ERROR}
                    size={buttonSizes.SMALL}
                    text="Deixar de Seguir"
                  />
                </OnboardingListItem>
              ))}
              {favorites.tournaments.length === 0 && (
                <EmptyState>Nenhum campeonato selecionado.</EmptyState>
              )}
            </OnboardingContainer>
          </OnboardingSelections>
        </SelectionsContainer>
      </Fragment>
    );
  }

  render() {
    const { type } = this.props;

    if (type === "teams") return this.renderTeamSelection();
    if (type === "tournaments") return this.renderTournamentSelection();

    return "";
  }
}

const SelectionsContainer = styled.div`
  display: flex;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const OnboardingList = styled.div`
  flex-basis: 50%;
  margin-right: 8px;
`;

const OnboardingSelections = styled.div`
  margin-left: 8px;
  flex-basis: 50%;
`;

const EmptyState = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.GRAY_LIGHT};
  height: 100%;
`;

const OnboardingContainer = styled.div`
  overflow-y: scroll;
  height: 330px;

  @media screen and (max-width: 576px) {
    height: 200px;
  }
`;

const OnboardingTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin: 12px 0;
`;

const OnboardingListItem = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 0px;
  background: transparent;
  width: 100%;
  height: 60px;

  span {
    flex: 1;
    text-align: left;
    font-size: 14px;
  }
`;

const Logo = styled(TeamLogo)`
  margin-right: 12px;
`;

const Image = styled.div`
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: cover;
  margin-right: 12px;
`;

export default OnboardingItemList;
