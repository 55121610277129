import Icon from "./Icon";
import styled from "styled-components";
import { colors } from "../../config/styles";

const LoadingIndicator = (props) => (
  <LoadingIndicatorContainer>
    <Icon size={24} name="spinner" spin />
    <LoadingText white={props.white}>{props.text}</LoadingText>
  </LoadingIndicatorContainer>
);

const LoadingIndicatorContainer = styled.div`
  font-size: 24px;
  color: ${colors.ACCENT};
  fill: ${colors.ACCENT};
  text-align: center;
  padding: 24px 0;
`;

const LoadingText = styled.p`
  font-size: 12px;
  color: ${(props) => (!props.white ? colors.GRAY_DARK : "white")};
`;

export default LoadingIndicator;
