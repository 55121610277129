import React, { Component } from 'react';

// 3rd
import { Formik } from 'formik';
import * as Yup from 'yup';

// components
import Input from '../Input';
import styled from 'styled-components';
import {colors} from '../../../config/styles';
import { UserContext } from '../../../contexts/UserContext'

class CompleteProfileForm extends Component {
  static contextType = UserContext;

  render() {
    const {
      bindSubmitForm,
      onFormSubmit,
    } = this.props;

    const {
      userData,
    } = this.context;

    return (
      <Formik
        initialValues={{
          email: userData.email || '',
          fullName: userData.fullName || '',
        }}
        validationSchema={Yup.object({
          email: Yup
            .string()
            .email('Email inválido')
            .required('Obrigatório'),
          fullName: Yup
            .string()
            .required('Obrigatório'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          onFormSubmit(values);
          setSubmitting(false);
        }}
      >
        {(formik) => {
          bindSubmitForm(formik.submitForm);

          return (
            <Form onSubmit={formik.handleSubmit}> 
              <InputGroup>
                <Input
                  name="email"
                  placeholder="ratodoglobal@csgo.com"
                  label="Email"
                  {...formik.getFieldProps('email')}
                />

                {formik.touched.email && formik.errors.email ? (
                  <Error>{formik.errors.email}</Error>
                ) : null}
              </InputGroup>

              <InputGroup>
                <Input
                  name="fullName"
                  placeholder="Digite..."
                  label="Nome"
                  {...formik.getFieldProps('fullName')}
                />

                {formik.touched.fullName && formik.errors.fullName ? (
                  <Error>{formik.errors.fullName}</Error>
                ) : null}
              </InputGroup>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const Form = styled.form`
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: left;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const InputGroup = styled.div`
  flex: 1;

  &:first-child {
    margin-right: 16px;
  }

  @media screen and (max-width: 768px) {
    &:first-child {
      margin-right: 0px;
      margin-bottom: 16px;
    }
  }
`

const Error = styled.span`
  color: ${colors.ERROR};
  font-size: 12px;
`

export default CompleteProfileForm;
