import axios from "axios";
import { API_BASE_URL } from "../config/constants";

export default class TournamentsService {
  static getTournaments(
    page = 1,
    amount = 10,
    finished = false,
    featured = false,
    team = null,
    searchQuery = null,
    ids = null
  ) {
    const url = `${API_BASE_URL}/tournaments`;

    const params = {
      page,
      amount,
      finished: finished ? 1 : 0,
      featured: featured ? 1 : 0,
      team,
      searchQuery,
      ids,
    };

    return axios.get(url, { params });
  }

  static getTournamentDetail(tournamentId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}`;

    return axios.get(url);
  }

  static getTournamentStages(tournamentId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}/stages`;

    return axios.get(url);
  }

  static getTournamentBrackets(tournamentId, stageId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}/stages/${stageId}/substages/brackets`;

    return axios.get(url);
  }

  static getTournamentGroups(tournamentId, stageId) {
    const url = `${API_BASE_URL}/tournaments/${tournamentId}/stages/${stageId}/substages/groups`;

    return axios.get(url);
  }
}
