import React, { Component } from 'react';
import styled from 'styled-components';

export default class Card extends Component {
  render() {
    return (
      <CardContainer className={this.props.className} noPadding={this.props.noPadding}>
        {this.props.children}
      </CardContainer>
    );
  }
}

const CardContainer = styled.div`
  background: white;
  box-shadow: 0 0px 35px rgba(50,50,93,.01), 0 5px 15px rgba(0,0,0,.07);
  padding: ${props => props.noPadding ? '0px' : '20px'};
`;